import type { HeaderProps } from '@dx-ui/osc-header';
import type { Footer } from '@dx-ui/osc-footer';
import { env } from '@dx-ui/framework-env';

export const getHeaderProps = (): Omit<HeaderProps, 'onSignInAttempt' | 'onSignOut'> => ({
  isFluid: false,
  brand: { code: 'WW', name: 'Hilton For the Stay', url: `${env('OHW_BASE_URL')}en/` },
  megaNavLinks: [
    {
      label: 'Hotels & Resorts',
      link: { url: '', isNewWindow: false, showNewWindowIcon: false },
      subMenu: [
        {
          label: 'Locations',
          link: {
            url: `${env(
              'OHW_BASE_URL'
            )}en/locations/?cid=OH,WW,LocationsNav,MULTIPR,Header,Home,Brand-Ibm`,
            isNewWindow: false,
            showNewWindowIcon: false,
          },
        },
        {
          label: 'Brands',
          link: {
            url: `${env('OHW_BASE_URL')}en/brands/`,
            isNewWindow: false,
            showNewWindowIcon: false,
          },
        },
        {
          label: 'Travel Inspiration',
          link: {
            url: `${env('OHW_BASE_URL')}en/travel/`,
            isNewWindow: false,
            showNewWindowIcon: false,
          },
        },
        {
          label: 'All-Inclusive',
          link: {
            url: `${env('OHW_BASE_URL')}en/all-inclusive/`,
            isNewWindow: false,
            showNewWindowIcon: false,
          },
        },
        {
          label: 'Resorts',
          link: {
            url: `${env('OHW_BASE_URL')}en/resorts/`,
            isNewWindow: false,
            showNewWindowIcon: false,
          },
        },
      ],
    },
    {
      label: 'Hilton Honors',
      link: { url: '', isNewWindow: false, showNewWindowIcon: false },
      subMenu: [
        {
          label: 'About Hilton Honors',
          link: {
            url: `${env('OHW_BASE_URL')}en/hilton-honors/`,
            isNewWindow: false,
            showNewWindowIcon: false,
          },
        },
        {
          label: 'Join Now',
          link: {
            url: `${env('OHW_BASE_URL')}en/hilton-honors/join/`,
            isNewWindow: false,
            showNewWindowIcon: false,
          },
        },
        {
          label: 'Member Benefits',
          link: {
            url: `${env('OHW_BASE_URL')}en/hilton-honors/member-benefits/`,
            isNewWindow: false,
            showNewWindowIcon: false,
          },
        },
        {
          label: 'Hilton Honors Points',
          link: {
            url: `${env('OHW_BASE_URL')}en/hilton-honors/points/`,
            isNewWindow: false,
            showNewWindowIcon: false,
          },
        },
      ],
    },
    {
      label: 'Offers',
      link: {
        url: `${env('OHW_BASE_URL')}en/offers/?cid=OH,WW,OffersNav,MULTIPR,Header,Home,Brand-Ibm`,
        isNewWindow: false,
        showNewWindowIcon: false,
      },
    },
    {
      label: 'Meetings & Events',
      link: { url: '', isNewWindow: false, showNewWindowIcon: false },
      subMenu: [
        {
          label: 'All Events',
          link: {
            url: `${env(
              'OHW_BASE_URL'
            )}en/events/?cid=OH,WW,MeetingsEventsNav,MULTIPR,Header,Home,Brand-Ibm`,
            isNewWindow: false,
            showNewWindowIcon: false,
          },
        },
        {
          label: 'Group Travel',
          link: {
            url: `${env('OHW_BASE_URL')}en/events/groups/`,
            isNewWindow: false,
            showNewWindowIcon: false,
          },
        },
        {
          label: 'Meetings & Conferences',
          link: {
            url: `${env('OHW_BASE_URL')}en/events/meetings/`,
            isNewWindow: false,
            showNewWindowIcon: false,
          },
        },
        {
          label: 'Weddings',
          link: {
            url: `${env('OHW_BASE_URL')}en/events/weddings/`,
            isNewWindow: false,
            showNewWindowIcon: false,
          },
        },
        {
          label: 'Celebrations',
          link: {
            url: `${env('OHW_BASE_URL')}en/events/celebrations/`,
            isNewWindow: false,
            showNewWindowIcon: false,
          },
        },
      ],
    },
    {
      label: 'Credit Cards',
      link: {
        url: `${env(
          'OHW_BASE_URL'
        )}en/hilton-honors/credit-cards/?cid=OH,WW,CobrandNav,MULTIPR,Header,Home,Brand-Ibm`,
        isNewWindow: false,
        showNewWindowIcon: false,
      },
    },
    {
      label: 'Hilton Getaways',
      link: {
        url: 'https://www.hiltongetaways.com/?cid=OH,WW,GetawaysNav,MULTIPR,Header,Home,Brand-Ibm',
        isNewWindow: true,
        showNewWindowIcon: true,
      },
    },
    {
      label: 'Hilton Grand Vacations',
      link: {
        url: 'https://www.hiltongrandvacations.com/en/discover-membership/the-hgv-difference',
        isNewWindow: true,
        showNewWindowIcon: true,
      },
    },
  ],
  loginOptions: {
    options: {
      showCreatePassword: true,
      forgetInfoLink: {
        url: `${env('OHW_BASE_URL')}en/hilton-honors/login/forgot-information/`,
      },
      secureInfoLink: {
        url: `${env('OHW_BASE_URL')}en/p/global-privacy-statement`,
      },
      joinLink: { url: `${env('OHW_BASE_URL')}en/hilton-honors/join/`, underline: false },
    },
  },
});

export const getFooterProps = (): Footer => ({
  contactPhoneLink: { url: 'tel:+1-800-445-8667', label: '+1-800-HILTONS' },
  links: [
    { isNewWindow: false, url: 'https://stories.hilton.com', label: 'Media' },
    {
      isNewWindow: false,
      url: `${env('OHW_BASE_URL')}en/corporate/development/`,
      label: 'Development',
    },
    { isNewWindow: false, url: 'https://jobs.hilton.com', label: 'Careers' },
    { isNewWindow: false, url: `${env('OHW_BASE_URL')}en/p/sitemap/`, label: 'Site Map' },
    {
      isNewWindow: false,
      url: `${env('OHW_BASE_URL')}en/p/global-privacy-statement/`,
      label: 'Global Privacy Statement',
    },
    { isNewWindow: false, url: 'https://www.buyhiltongiftcards.com/', label: 'Hilton Gift Card' },
    {
      isNewWindow: false,
      url: `${env(
        'OHW_BASE_URL'
      )}en/travel/?cid=OH,MB,THFooter,MULTIPR,Tile,AboutUs,SingleLink,i81797`,
      label: 'Travel Inspiration',
    },
    {
      adaDescription: '',
      isNewWindow: false,
      url: `${env(
        'OHW_BASE_URL'
      )}en/brands/?cid=OH,MB,OurBrandsFooter,MULTIPR,Aux,Home,SingleLink,i84866`,
      label: 'Our Brands',
    },
    { isNewWindow: false, url: 'https://help.hilton.com/s/', label: 'Customer Support' },
    {
      isNewWindow: false,
      url: `${env('OHW_BASE_URL')}en/p/accessibility/`,
      label: 'Web Accessibility',
    },
    {
      adaDescription: '',
      isNewWindow: true,
      url: 'https://esg.hilton.com/',
      label: 'Corporate Responsibility',
    },
    {
      isNewWindow: false,
      url: `${env('OHW_BASE_URL')}en/hilton-honors/terms/`,
      label: 'Hilton Honors Discount Terms & Conditions',
    },
    {
      isNewWindow: true,
      url: 'https://cr.hilton.com/wp-content/uploads/2021/06/Hilton-Slavery-and-Trafficking-Statement-2020.pdf',
      label: 'Modern Slavery and Human Trafficking',
    },
    {
      isNewWindow: false,
      url: `${env('OHW_BASE_URL')}en/hilton-honors/personal-data-requests/`,
      label: 'Do Not Sell or Share My Information',
    },
    {
      isNewWindow: false,
      url: `${env('OHW_BASE_URL')}en/hilton-honors/personal-data-requests/`,
      label: 'Personal Data Requests',
    },
    {
      isNewWindow: false,
      url: `${env('OHW_BASE_URL')}en/p/site-usage-agreement/`,
      label: 'Site Usage Agreement',
    },
    {
      isNewWindow: false,
      url: `${env('OHW_BASE_URL')}en/p/partner-accommodations-terms/`,
      label: 'Partner Accommodations Terms',
    },
    {
      isNewWindow: false,
      url: 'https://www.hiltongetaways.com/?cid=OH,MB,HiltonGetawaysFooter,MultiBR,Aux,Home,SingleLink,i81681,',
      label: 'Hilton Getaways',
    },
  ],
  socialLinks: [
    { channel: 'Facebook' as const, url: 'https://www.facebook.com/hiltonnewsroom/' },
    { channel: 'Twitter' as const, url: 'https://twitter.com/hiltonnewsroom' },
    { channel: 'Instagram' as const, url: 'https://www.instagram.com/hiltonnewsroom/' },
  ],
});
